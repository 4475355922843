import TextField from '@mui/material/TextField';
import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Color from "../../assets/colors";
import { Typography } from '@mui/material';
import { FontSize } from '../../assets/font';


function Register() {
		
	return (
		<Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
		  item
          sm={8}
          md={5}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
		<Grid item xs={12} sm={4} md={7} component={Paper} elevation={6} square>
		<Box m={5} pt={5}>
			<Grid>
				<Typography
					style={{
						fontSize: FontSize.header1,
						fontWeight: "bold",
					}}
				>
					Register
				</Typography>
				<Box component="form" noValidate sx={{ mt: 1 }}>
				<Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
			  <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="jobTitle"
                  label="Job Title"
                  name="jobTitle"
                  autoComplete="job-title"
                />
              </Grid>
			  <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="businessArea"
                  label="Business Area"
                  name="businessArea"
                  autoComplete="business-area"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
			 
              
            </Grid>
					<Grid>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Register
						</Button>
					</Grid>
					<Grid container>
						<Grid
							style={{
								width: "100%",
							}}
						>
							<Typography>Already have an account? </Typography>
							<Link href="/login" variant="body2">
								Log in.
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Box>
		</Grid>
		</Grid>
	)
}

export default Register;