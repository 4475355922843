export const FontSize = {
    header1: "64px",
    header2: "48px",
    header3: "36px",
    header4: "24px",
    subheading: "18px",
    body: "18px",
    caption: "16px",
    bold: "bold",
    regular: "regular",
  };