import TextField from '@mui/material/TextField';
import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FontSize } from '../../assets/font';
import {
	HeaderOne
} from "./styles";

function Login() {
	return (
		<Grid>
			<Grid>
				<HeaderOne>
					Log In
				</HeaderOne>
				<Box component="form" noValidate sx={{ mt: 1 }}>
					<Grid>
						<Typography
							style={{
								fontSize: FontSize.header4,
							}}
						>
							Email
						</Typography>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
					</Grid>
					<Grid>
						<Typography
							style={{
								fontSize: FontSize.header4,
							}}
						>
							Password
						</Typography>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
					</Grid>
					<Grid>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Log In
						</Button>
					</Grid>
					<Grid container>
						<Grid
							style={{
								width: "100%",
							}}
						>
							<Typography>New to the site? </Typography>
							<Link href="/register" variant="body2">
								Register.
							</Link>
						</Grid>
						<Grid>
							<Typography>Forgotten your password? </Typography>
							<Link href="#" variant="body2">
								{"Click here."}
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Login;